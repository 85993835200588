<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="vue-form-wizard mb-3 md">
          <div class="d-flex justify-content-between flex-wrap mr-1 ml-1">
            <div class="d-flex align-items-center mb-1 mt-1">
              <span class="">Show</span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20', '50', '100']"
                class="mx-1"
              />
              <span class="text-nowrap">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle.collapse-1
                  @click="refreshView"
                  variant="primary"
                >
                  Create Region Group
                </b-button>
              </span>
            </div>
            <div>
              <div class="custom-search d-flex justify-content-right mt-1 mb-1">
                <div class="d-flex align-items-center">
                  <span class="mr-1">Search</span>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </div>
            </div>
          </div>

          <b-collapse id="collapse-1" class="ml-1 mr-1">
            <CreateRegionGroupWizard></CreateRegionGroupWizard>
          </b-collapse>

          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field === 'actions'">
                <span>
                  <feather-icon
                    icon="TrashIcon"
                    @click="showDeleteModal(props.row._id, props.row.name)"
                    class="ml-1 mr-1 table-icon trash-icon"
                  />
                  <feather-icon
                    @click="handleRouteToEditor(props.row._id)"
                    icon="EditIcon"
                    class="mr-1 table-icon"
                  />
                </span>
              </span>
              <!-- Column: Name -->
              <span v-else-if="props.column.field === 'name'">
                <div @click="handleRouteToEditor(props.row._id)" class="link">
                  {{ props.row.name }}
                </div>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- Pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap mb-1 ml-1"> Show</span>

                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '20', '50', '100']"
                    class="mx-1 mb-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-1 mr-1"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <!-- Deletion Modal -->
          <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Delete Region Group"
            ok-title="Delete"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
          >
            <h6>Warning</h6>
            <p>
              Deleting this Region Group will delete all Sites attached to
              it.<br /><br />
              Please enter in the name of the Region Group to confirm. <br />
            </p>
            <span
              >Name:
              <h6>{{ groupToDelete.name }}</h6></span
            >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group
                label="Name"
                label-for="name-input"
                invalid-feedback="Name Must Match Region Group Name"
                :state="nameState"
              >
                <b-form-input
                  id="name-input"
                  v-model="name"
                  :state="nameState"
                  required
                ></b-form-input>
              </b-form-group>
            </form>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BModal,
  BDropdownItem,
  BCollapse,
  BBadge,
  BFormGroup,
  BButton,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// Import Icons
import { EyeIcon, MoreVerticalIcon } from 'vue-feather-icons'
// import api
import { regionGroup } from '@/services/regionGroup.service.js'
// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// import Site Creation Wizard
import CreateRegionGroupWizard from './CreateRegionGroupWizard.vue'
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

// Declare component
export default {
  name: 'RegionManager',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    BCollapse,
    BButton,
    VBToggle,
    BModal,
    BBadge,
    BPagination,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    EyeIcon,
    MoreVerticalIcon,
    CreateRegionGroupWizard,
    VueGoodTable
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      pageLength: 5,
      columns: [
        {
          label: 'Region',
          field: 'name',
          width: '50%'
        },
        {
          label: 'Sites',
          field: 'sites',
          width: '40%'
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '10%'
        }
      ],
      rows: [],
      status: [
        {
          1: 'Published',
          2: 'Unpublished'
        },
        {
          1: 'light-success',
          2: 'light-warning'
        }
      ],
      searchTerm: '',
      groupToDelete: {
        name: '',
        id: ''
      },
      name: '',
      nameState: null
    }
  },
  methods: {
    handleRouteToEditor(id) {
      this.$router.push('/regioneditor/' + id)
    },
    checkFormValidity() {
      const valid = this.name === this.groupToDelete.name ? true : false
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    refreshView() {
      // emit event to refresh view
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 10)
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.deleteRegionGroup(this.groupToDelete.id)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    showDeleteModal(id, name) {
      this.groupToDelete.name = name
      this.groupToDelete.id = id
      this.$bvModal.show('modal-prevent-closing')
    },

    async deleteRegionGroup(id) {
      regionGroup
        .deleteRegionGroup({ groupId: id })
        .then((res) => {
          window.Bus.instance.$emit('pageSuccess', {
            header: 'SUCCESS',
            detail: res.message
          })
          this.getAllRegionGroups()
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'ERROR',
            detail: error.message
          })
        })
    },
    async getAllRegionGroups() {
      try {
        regionGroup.getAllRegionGroupsWithNumberOfSites().then((data) => {
          this.rows = data.data
        })
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: error.response.data
            ? error.response.data.error
            : error.response.statusText
        })
      }
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Published: 'light-success',
        Unpublished: 'light-warning'
        /* eslint-enable key-spacing */
      }
      return (status) => statusColor[status]
    }
  },
  created: function () {
    let that = this
    window.Bus.instance.$on('updateRegionList', () => {
      // refresh Site List
      that.getAllRegionGroups()
      // Minimize Site Creation Wizard
      that.$root.$emit('bv::toggle::collapse', 'collapse-1')
    })
  },
  async mounted() {
    this.getAllRegionGroups()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
.table-icon {
  color: $primary;
  //height: 16px !important;
  //width: 16px !important;
}
.trash-icon {
  color: $danger !important;
}
.align-text-center {
  text-align: center;
}
.link:hover {
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
}
/* The Close Button */
.close {
  margin-right: -3px !important;
  margin-top: -3px !important;
}

.map-container {
  height: 40vh;
  width: 100%;
}
</style>
