<template>
  <b-card>
    <b-form ref="form">
      <validation-observer ref="formCheck">
        <b-row>
          <b-col md="6" align-self="center">
            <b-form-group label="Region Group Name" label-for="site-name">
              <validation-provider
                rules="required"
                name="Region Group Name"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="regionGroup.name"
                  placeholder="Name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <CreateMap
              @addpolygonlocation="addLocation"
              @updatepolygonlocation="updateLocation"
              @deletepolygonlocation="deleteLocation"
              :drawControl="true"
              :key="this.componentKey"
            >
            </CreateMap>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right" md="12">
            <br />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              @click.prevent="validationForm"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import { regionGroup } from '@/services/regionGroup.service'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import CreateMap from '@/components/maps/MapComponent.vue'
import {
  BRow,
  BButton,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BCard
} from 'bootstrap-vue'
import Vue from 'vue'
export default {
  name: 'CreateRegionGroupWizard',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    CreateMap,
    Vue,
    BRow,
    BButton,
    BCol,
    BCard,
    BFormGroup,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data: () => ({
    required,
    componentKey: 0,
    regionGroup: {
      name: '',
      geoJSON: {
        type: 'FeatureCollection',
        features: []
      }
    },
    submitted: false
  }),
  methods: {
    reset() {
      this.regionGroup = {
        name: '',
        geoJSON: {
          type: 'FeatureCollection',
          features: []
        }
      }
      this.submitted = false
      this.$refs.formCheck.reset()
      this.$refs.form.reset()
      this.componentKey += 1
    },
    addLocation(createLocation) {
      this.regionGroup.geoJSON.features.push(createLocation)
    },
    updateLocation(updateLocation) {
      this.regionGroup.geoJSON.features[0] = updateLocation
    },
    deleteLocation(deleteLocation) {
      this.regionGroup.geoJSON.features.splice(deleteLocation)
    },
    validationForm() {
      this.$refs.formCheck.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          regionGroup
            .createRegionGroup(this.regionGroup)
            .then((res) => {
              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'Region Group Successfully created'
              })

              window.Bus.instance.$emit('updateRegionList')

              setTimeout(() => {
                this.reset()
              }, 10)
            })
            .catch((error) => {
              if (
                error.message ===
                'RegionGroup validation failed: name: is already taken.'
              ) {
                window.Bus.instance.$emit('pageDanger', {
                  header: 'ERROR',
                  detail: 'Name Already Taken'
                })
              } else {
                window.Bus.instance.$emit('pageDanger', {
                  header: 'ERROR',
                  detail: 'Could not create new Region Group'
                })
              }
            })
        }
      })
    }
  }
}
</script>
